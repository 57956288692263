const answers = [[
    {
        title: '中国共产党是（）诞生的。',
        options: {
            A: '1919',
            B: '1920',
            C: '1921',
        },
        answer: 'C'
    },
    {
        title: '中国早期共产主义小组有（）个。',
        options: {
            A: '8',
            B: '9',
            C: '10',
        },
        answer: 'A'
    },
    {
        title: '北伐战争时期，农民运动的中心在（）。',
        options: {
            A: '江西',
            B: '湖南',
            C: '广东',
        },
        answer: 'B'
    },
    {
        title: '全民族抗战的起点是（）。',
        options: {
            A: '九一八事变',
            B: '华北事变',
            C: '七七事变',
        },
        answer: 'C'
    },
    {
        title: '我党提出全面抗战路线的会议是（）。',
        options: {
            A: '遵义会议',
            B: '瓦窑堡会议',
            C: '洛川会议',
        },
        answer: 'C'
    },
    {
        title: '在（A）上毛泽东思想被确立为党的指导思想。',
        options: {
            A: '党的七大',
            B: '党的八大',
            C: '党的十五大',
        },
        answer: 'A'
    },
    {
        title: '党在民主革命时期的三大法宝是（）。',
        options: {
            A: '实事求是、独立民主、群众路线',
            B: '统一战线、武装斗争、党的建设',
            C: '解放思想、实事求是、与时俱进',
        },
        answer: 'B'
    },
    {
        title: '党的七大是在（）召开的。',
        options: {
            A: '北京',
            B: '延安',
            C: '西柏坡',
        },
        answer: 'B'
    },
    {
        title: '中华人民共和国建国后头三年的主要任务是（）。',
        options: {
            A: '没收官僚资本，进行土地改革',
            B: '恢复国民经济',
            C: '进行社会主义改造',
        },
        answer: 'B'
    },
    {
        title: '中国新民主主义革命伟大开端的标志是（）。',
        options: {
            A: '辛亥革命',
            B: '五四运动',
            C: '中国共产党的成立',
        },
        answer: 'B'
    },
    {
        title: '1931年11月27日，中华苏维埃共和国临时中央政府在（）成立。',
        options: {
            A: '瑞金',
            B: '兴国',
            C: '于都',
        },
        answer: 'A'
    },
    {
        title: '我国发展国民经济的第一个五年计划开始于（）年',
        options: {
            A: '1951',
            B: '1952',
            C: '1953',
        },
        answer: 'C'
    },
    {
        title: '中国是于（）恢复在联合国的合法席位的。',
        options: {
            A: '1971年10月',
            B: '1972年2月',
            C: '1972年9月',
        },
        answer: 'A'
    },
    {
        title: '“建设有中国特色的社会主义”是邓小平在（）上最早提出来的。',
        options: {
            A: '党的十一届三中全会',
            B: '党的十二大',
            C: '党的十三',
        },
        answer: 'B'
    },
    {
        title: '党在社会主义初级阶段的基本路线的核心是（）。',
        options: {
            A: '一个中心，两个基本点',
            B: '以经济建设为中心',
            C: '坚持四项基本原则，坚持改革开放',
        },
        answer: 'A'
    },
    {
        title: '中华人民共和国成立时，中国共产党党员()。',
        options: {
            A: '450万',
            B: '340万',
            C: '500万',
        },
        answer: 'A'
    },
    {
        title: '1958年6月1日，中共中央的理论刊物正式创刊，它是（）。',
        options: {
            A: '《红旗》',
            B: '《求是》',
            C: '《党建》',
        },
        answer: 'A'
    },
    {
        title: '发展党员应坚持（）原则。',
        options: {
            A: '个别吸收',
            B: '成批发展',
            C: '突击发展',
        },
        answer: 'A'
    },
    {
        title: '中国共产党的根本组织原则是（）。',
        options: {
            A: '集体领导',
            B: '民主集中制',
            C: '民主制',
        },
        answer: 'B'
    },
    {
        title: '“三个代表”重要思想形成的标志是（）。',
        options: {
            A: '2000年江泽民在广东茂名、高州的讲话',
            B: '2001年江泽民“七一讲话”',
            C: '2002年江泽民在党的十六大上的报告',
        },
        answer: 'B'
    },
    {
        title: '（），北京赢得2008年奥运会主办权。',
        options: {
            A: '1999年7月13日',
            B: '2000年7月13日',
            C: '2001年7月13日',
        },
        answer: 'C'
    },
    {
        title: '邓小平（）年在接见美国和加拿大客人时，首次明确提出，社会主义也可以搞市场经济。',
        options: {
            A: '1979',
            B: '1987',
            C: '1992',
        },
        answer: 'A'
    },
    {
        title: '（）的崛起，是农村改革中的重大进步。',
        options: {
            A: '乡政府',
            B: '乡镇企业',
            C: '人口流动',
        },
        answer: 'B'
    },
    {
        title: '中国共产党的宗旨是（）。',
        options: {
            A: '实现社会主义现代化',
            B: '坚持党的基本路线和基本纲领不动摇',
            C: '全心全意为人民服务',
        },
        answer: 'C'
    },
    {
        title: '党对待犯错误的同志的基本方针是（）。',
        options: {
            A: '严肃处理',
            B: '惩前毖后，治病救人',
            C: '耐心帮助和教育',
        },
        answer: 'B'
    },
    {
        title: '全面建设小康社会的奋斗目标是在党的（）上提出来的。',
        options: {
            A: '十四大',
            B: '十五大',
            C: '十六大',
        },
        answer: 'C'
    },
    {
        title: '党章规定，党的各级代表大会的代表和委员会的选举要采取（）方式。',
        options: {
            A: '举手表决',
            B: '无记名投票',
            C: '记名投票',
        },
        answer: 'B'
    },
    {
        title: '党的全部工作和战斗力的基础是（）。',
        options: {
            A: '党中央',
            B: '党的地方委员会',
            C: '党的基层组织',
        },
        answer: 'C'
    },
    {
        title: '十六大党章规定的党的纪律处分有（）。',
        options: {
            A: '四种',
            B: '五种',
            C: '六种',
        },
        answer: 'B'
    },
    {
        title: '1999年11月，中央经济工作会议正式作出关于实施（）。',
        options: {
            A: '科教兴国战略',
            B: '可持续发展战略',
            C: '西部大开发战略',
        },
        answer: 'C'
    },
    {
        title: '现阶段中国最大的国情是（）。',
        options: {
            A: '生产力水平低，经济发展落后',
            B: '社会主义市场经济体制不完善',
            C: '处于并将长期处于社会主义初级阶段',
        },
        answer: 'C'
    },
    {
        title: '党的十六大是我们党在（）召开的十分重要的代表大会，对于党和国家事业的发展具有重大而深远的意义。',
        options: {
            A: '新形势',
            B: '新世纪新阶段',
            C: '新世纪',
        },
        answer: 'B'
    },
    {
        title: '马克思列宁主义揭示了（），它的基本原理是正确的，具有强大的生命力。',
        options: {
            A: '人类改造客观世界的规律',
            B: '社会主义和共产主义运动的规律',
            C: '人类社会历史发展的规律',
        },
        answer: 'C'
    },
    {
        title: '我国正处于并将长期处于（）。这是在经济文化落后的中国建设社会主义现代化不可逾越的历史阶段，需要上百年的时间。',
        options: {
            A: '社会主义阶段',
            B: '社会主义初级阶段',
            C: '共产主义阶段',
        },
        answer: 'B'
    },
    {
        title: '党的领导主要是（）的领导。',
        options: {
            A: '政治、经济和文化',
            B: '政治、思想和组织',
            C: '政治、国防和外交',
        },
        answer: 'B'
    },
    {
        title: '党的思想路线是一切从实际出发，理论联系实际，（），在实践中检验真理和发展真理。',
        options: {
            A: '实事求是',
            B: '开拓创新',
            C: '解放思想',
        },
        answer: 'A'
    },
    {
        title: '全面建设小康社会，最根本的是坚持（），不断解放和发展社会生产力。',
        options: {
            A: '以经济建设为中心',
            B: '以发展为中心',
            C: '以改革开放为中心',
        },
        answer: 'A'
    },
    {
        title: '坚持一个中国原则，是发展两岸关系和实现和平统一的基础。世界上只有一个中国，大陆和台湾同属一个中国，中国的（）不容分割。',
        options: {
            A: '主权和领土完整',
            B: '主权和统一',
            C: '统一和领土完整',
        },
        answer: 'A'
    },
    {
        title: '加强和改进党的建设，必须加强党的执政能力建设，提高党的（）。',
        options: {
            A: '抵御风险能力和水平',
            B: '驾驭全局能力和水平',
            C: '领导水平和执政水平',
        },
        answer: 'C'
    },
    {
        title: '预备党员必须面向党旗进行入党宣誓。誓词如下：我志愿加入中国共产党，拥护党的纲领，遵守党的章程，履行党员义务，()，严守党的纪律，保守党的秘密，对党忠诚，积极工作，为共产主义奋斗终身，随时准备为党和人民牺牲一切，永不叛党。',
        options: {
            A: '执行党的决定',
            B: '执行党的路线',
            C: '执行党的政策',
        },
        answer: 'A'
    },
    {
        title: '党员如果没有正当理由，连续()不参加党的组织生活，或不交纳党费，或不做党所分配的工作，就被认为是自行脱党。',
        options: {
            A: '三个月',
            B: '六个月',
            C: '一年',
        },
        answer: 'B'
    },
    {
        title: '预备党员认真履行党员义务，具备党员条件的，应当按期转为正式党员；需要继续考察和教育的，可以延长预备期，但不能超过（）。',
        options: {
            A: '半年',
            B: '一年',
            C: '一年半',
        },
        answer: 'B'
    }
], [
    {
        title: '1936年10月，红军三大主力在甘肃（）胜利会师，从而结束了具有伟大历史意义的长征。',
        options: {
            A: '吴起镇',
            B: '直罗镇',
            C: '会宁',
        },
        answer: 'C'
    },
    {
        title: '（），红军三大主力在甘肃会宁胜利会师，从而结束了具有伟大历史意义的长征。',
        options: {
            A: '1934年10月',
            B: '1936年10月',
            C: '1938年10月',
        },
        answer: 'B'
    },
    {
        title: '由于中国共产党内的（），红军第五次反“围剿”失利，被迫进行长征。',
        options: {
            A: '“左”倾错误',
            B: '“右”倾错误 ',
            C: '整风运动',
        },
        answer: 'A'
    },
    {
        title: '（）确立了以毛泽东为代表的中共中央的正确领导，成为中国共产党和中国革命历史的转折点。',
        options: {
            A: '瓦窑堡会议',
            B: '遵义会议',
            C: '娄山关会议',
        },
        answer: 'B'
    },
    {
        title: '中国工农红军长征的三大主力是（）。',
        options: {
            A: '中央红军,红二,四方面军',
            B: '红一,二,三方面军',
            C: '红二十五,二十七军和红十五军团',
            D: '红二,六军团,四方面军'
        },
        answer: 'A'
    },
    {
        title: '红军长征的原因（）。',
        options: {
            A: '日本的入侵',
            B: '北上抗日',
            C: '伪满洲国的建立',
            D: '第五次反"围剿"的失败'
        },
        answer: 'D'
    },
    {
        title: '红军长征跳出敌人的包围是在（）。',
        options: {
            A: '四渡赤水河',
            B: '渡过金沙江',
            C: '强渡大渡河',
            D: '翻过大雪山'
        },
        answer: 'B'
    },
    {
        title: '长征途中,中共历史上具有生死攸关的转折意义的大会是（）。',
        options: {
            A: '两河口会议',
            B: '巴西会议',
            C: '俄界会议',
            D: '遵义会议'
        },
        answer: 'D'
    },
    {
        title: '1931年1月,遵义会议解决了当时最为紧迫的（）。',
        options: {
            A: '政治和军事问题',
            B: '政治和思想问题',
            C: '军事和组织问题',
            D: '军事和作风问题'
        },
        answer: 'C'
    },
    {
        title: '中共中央作出《关于张国焘同志错误的决定》是在（）。',
        options: {
            A: '两河口会议上',
            B: '沙窝会议上',
            C: '巴西会议上',
            D: '俄界会议上'
        },
        answer: 'D'
    },
    {
        title: '"红军不怕远征难,万水千山只等闲……"中的"难"包括（）。',
        options: {
            A: '国民党的围追堵截 ',
            B: '穷山恶水的自然条件',
            C: '党内"左倾"错误的影响',
            D: 'ABC都有'
        },
        answer: 'D'
    },
    {
        title: '指挥红军粉碎国民党对中央苏区第四次"围剿"的是（）。',
        options: {
            A: '周恩来 朱德',
            B: '毛泽东 朱德',
            C: '毛泽东 周恩来',
            D: '朱德 陈毅'
        },
        answer: 'A'
    },
    {
        title: '长征途中,红军变被动为主动的首次军事行动是（）。',
        options: {
            A: '强渡乌江',
            B: '巧渡金沙江',
            C: '四渡赤水',
            D: '强渡大渡河'
        },
        answer: 'B'
    },
    {
        title: '在第五次反"围剿"斗争中,博古,李德推行王明"左"倾错误路线,提出的口号是（）。',
        options: {
            A: '"敌进我退,敌驻我扰,敌疲我打,敌退我追" ',
            B: '"御敌于国门外","不丧失守土"',
            C: '"稳扎稳打,步步为营" ',
            D: '"逐次转移,各个击破"'
        },
        answer: 'B'
    },
    {
        title: '遵义会议的最大贡献是（）。',
        options: {
            A: '确定了抗日民族统一战线的方针',
            B: '坚持了游击战争顺利掩护主力红军转移',
            C: '决定了红军继续北上进军陕甘宁',
            D: '事实上确立了以毛泽东为核心的中央领导'
        },
        answer: 'D'
    },
    {
        title: '1936年10月，中国工农红军第一方面军同红二、四方面军在哪里会师?（）。',
        options: {
            A: '西康的甘孜一带',
            B: '甘肃会宁',
            C: '甘肃平凉',
            D: '甘肃天水'
        },
        answer: 'B'
    },
    {
        title: '红军长征被称为什么?（）。',
        options: {
            A: '战略后退',
            B: '突围转移',
            C: '突围后退',
            D: '战略转移'
        },
        answer: 'D'
    },
    {
        title: '在哪一天,红军总政治部发布政治指令，提出“准备突破敌人的封锁线，进行长途行军与战斗”?(（）。',
        options: {
            A: '1934.10.9 ',
            B: '1934.10.10 ',
            C: '1934.10.1 ',
            D: '1935.10.9'
        },
        answer: 'A'
    },
    {
        title: '长征时中共中央红二、六军团组成第二方面军(将红四方面军之第三十二军编入第二方面军)的总指挥是谁?（）。',
        options: {
            A: '贺龙',
            B: '刘伯承',
            C: '任弼时',
            D: '肖克'
        },
        answer: 'A'
    },
    {
        title: '王明“左”倾冒险主义的特征是（）。',
        options: {
            A: '经验主义',
            B: '教条主义',
            C: '盲动主义'
        },
        answer: 'B'
    },
    {
        title: '1935年1月至3月，毛泽东等指挥的（），根据实际情况，灵活机动，迂回穿插于敌人重兵之间，成为我军战史上的光辉典范。',
        options: {
            A: '四渡赤水',
            B: '湘江之战',
            C: '娄山关之战'
        },
        answer: 'A'
    },
    {
        title: '长征途中,红军（）跳出了蒋介石几十万大军的围追堵截，变被动为主动。',
        options: {
            A: '渡乌江',
            B: '巧渡金沙江',
            C: '四渡赤水'
        },
        answer: 'B'
    },
    {
        title: '红军长征时经过大凉山彝族地区时，根据党的民族政策，与当地部落首领小叶丹歃血为盟的将领是（）。',
        options: {
            A: '朱德',
            B: '彭德怀',
            C: '刘伯承'
        },
        answer: 'C'
    },
    {
        title: '中央红军长征途中，蒋介石企图凭借天险大渡河，南攻北堵，围歼红军，妄图使中央红军成为（）第二。',
        options: {
            A: '项羽',
            B: '石达开',
            C: '李秀成'
        },
        answer: 'B'
    },
    {
        title: '强渡大渡河时，红军一部分先有（）名勇士作为先头部队强渡成功。',
        options: {
            A: '22',
            B: '19',
            C: '17'
        },
        answer: 'A'
    },
    {
        title: '1935年5月下旬，中央红军主力飞夺（），越过了被敌人视为不可逾越的天险大渡河。',
        options: {
            A: '泸定桥',
            B: '赵州桥',
            C: '卢沟桥'
        },
        answer: 'A'
    },
    {
        title: '长征路上中央红军翻越的第一座大雪山是（）。',
        options: {
            A: '长板山',
            B: '梦笔山',
            C: '夹金山'
        },
        answer: 'C'
    },
    {
        title: '1935年6月中旬，红一方面军克服了重重困难，翻过几座大雪山，到达（），与红四方面军胜利会师。',
        options: {
            A: '四川懋功',
            B: '四川安顺场',
            C: '四川甘孜'
        },
        answer: 'A'
    },
    {
        title: '红军两大主力会师后，分左右两路北上，右路军从四川松藩的毛尔盖出发，经（）天艰难跋涉过了荒无人烟的大草地。',
        options: {
            A: '15',
            B: '6',
            C: '7'
        },
        answer: 'C'
    },
    {
        title: '中央红军(红军陕甘支队)突破了天险腊子口，在甘肃宕昌县（），得到了陕北有刘志丹领导的红军和根据地的消息。',
        options: {
            A: '哈达铺镇',
            B: '榜罗镇',
            C: '吴起镇'
        },
        answer: 'A'
    },
    {
        title: '（）的胜利，给党中央把全国革命大本营放在西北的任务，举行了一个奠基礼。',
        options: {
            A: '包座战役',
            B: '突破腊子口',
            C: '直罗镇战役'
        },
        answer: 'C'
    },
    {
        title: '由于张国焘拒绝执行中共中央北上方针，命令一部分红军南下重过草地而导致重大伤亡，红四方面军由8万余人减为（）。',
        options: {
            A: '3万余人',
            B: '4万余人',
            C: '5万余人'
        },
        answer: 'A'
    },
    {
        title: '在中共中央劝导和督促下，经过（）及红四方面军广大指战员的斗争，张国焘被迫接受了中共中央北上的战略方针。',
        options: {
            A: '朱德、叶剑英',
            B: '朱德、刘伯承',
            C: '陈昌浩、徐向前'
        },
        answer: 'B'
    },
    {
        title: '1936年7月，红二、六军团到达四川甘孜地区，同（）胜利会师。',
        options: {
            A: '红一方面军',
            B: '红四方面军',
            C: '红二十五军'
        },
        answer: 'B'
    },
    {
        title: '红军长征后，项英、（）等领导的八省游击战争，在长江南北与敌人进行了艰苦卓绝的斗争，保存了革命火种。',
        options: {
            A: '陈毅',
            B: '杨靖宇',
            C: '刘志丹'
        },
        answer: 'A'
    },
    {
        title: '长征到达西北根据地的第一支红军是（）。',
        options: {
            A: '红一方面军',
            B: '红四方面军',
            C: '红二十五军'
        },
        answer: 'A'
    },
    {
        title: '1935年10月19日，中央红军到达（），胜利完成二万五千里长征。',
        options: {
            A: '陕西延安',
            B: '四川甘孜',
            C: '陕北吴起镇'
        },
        answer: 'C'
    },
    {
        title: '二万五千里（）所走的长征路程。',
        options: {
            A: '红一方面军',
            B: '红四方面军',
            C: '红二方面军'
        },
        answer: 'A'
    },
    {
        title: '中央红军长征共经过全国（）个省区。',
        options: {
            A: '10',
            B: '11',
            C: '12'
        },
        answer: 'B'
    },
    {
        title: '参加长征的共和国元帅有（）人。',
        options: {
            A: '10',
            B: '9',
            C: '8'
        },
        answer: 'B'
    },
    {
        title: '诗词（）是毛泽东在中央红军胜利完成了长征后，行至陕北清涧县袁家沟时写的。',
        options: {
            A: '《七律·长征》',
            B: '《念奴娇·昆仑》',
            C: '《沁园春·雪》'
        },
        answer: 'C'
    },
    {
        title: '“不到长城非好汉，屈指行程二万。”是毛泽东登上（）时的咏怀之作。',
        options: {
            A: '长城',
            B: '六盘山',
            C: '娄山关'
        },
        answer: 'B'
    },
    {
        title: '在红军长征中，（）是党中央、中央革命军事委员会出版的唯一报纸。',
        options: {
            A: '《红星报》',
            B: '《拂晓报》',
            C: '《先锋报》'
        },
        answer: 'A'
    },
    {
        title: '由于叛徒告密被捕的红军领导人（）在狱中坚贞不屈，写下了《可爱的中国》、《清贫》等闪耀着共产主义思想光辉的著作。',
        options: {
            A: '叶挺',
            B: '方志敏',
            C: '夏明翰'
        },
        answer: 'B'
    },
    {
        title: '（）在长征途中创作了24幅写生，成为记录长征唯一的形象史料和珍贵的艺术品。',
        options: {
            A: '廖承志',
            B: '黄镇',
            C: '舒同'
        },
        answer: 'B'
    },
    {
        title: '长征途中，在娄山关战斗中指挥部队担任主攻的南阳籍将领是（）。',
        options: {
            A: '李雪山',
            B: '谭政',
            C: '彭雪枫'
        },
        answer: 'C'
    },
    {
        title: '1932年10月，红（）方面军被迫离开根据地向西转移，途经南阳的新野、邓州、内乡、淅川等地。',
        options: {
            A: '二',
            B: '三',
            C: '四'
        },
        answer: 'C'
    }
]
]

export {
    answers
}

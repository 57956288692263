<template>
  <div>
    <div class="bg">
      <div class="answer-box">
        <div class="answer-item" v-for="(item, index) in topics" :key="index" v-show="current === index">
          <div class="answer-left">
            <div class="answer-header">
              <span class="answer-type">选择题</span>
              <span class="answer-title">{{ index + 1 }}、{{ item.title }}</span>
            </div>
            <div class="answer-options">
              <el-radio-group v-model="item.userAnswer" @change="changeRadio">
                <el-radio :label="n" v-for="(i, n) in item.options" class="answer-radio" :key="n">
                  {{ n }}、{{ i }}
                </el-radio>
              </el-radio-group>
            </div>
            <div class="answer-bottom">
              <div class="answer-bottom-item" @click="moveTopic(-1)">
                上一题
              </div>
              <div class="answer-bottom-item" @click="moveTopic(1)">
                下一题
              </div>
            </div>
          </div>
          <div class="answer-right">
            <div class="answer-right-header">
              <img src="../assets/images/dtk.png" alt="" class="answer-right-image">
              <span class="answer-right-title">答题卡</span>
            </div>
            <div class="answer-right-body">
              <div class="answer-card-item" v-for="(item, index) in topics" :key="index"
                   :class="[item.userAnswer ? 'current' : '']" @click="selectTopic(index)">
                {{ index + 1 }}
              </div>
              <div class="answer-card-view"></div>
              <div class="answer-card-view"></div>
            </div>
            <div class="answer-test">
              <el-button type="danger" class="answer-test-save" @click="test">交卷</el-button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="答题结果" v-model="dialogTestVisible" :close-on-click-modal="false" :close-on-press-escape="false"
               :show-close="false">
      <div class="test-box">
        <img src="../assets/images/test_success.png" alt="" class="test-success-image">
        <div class="test-num-box">
          得分：<span class="test-num">{{ num }}</span>
        </div>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="goIndex">返回首页</el-button>
          <el-button type="danger" @click="resetTopics">重新答题</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {answers} from "@/assets/js/answer"
import lodash from "lodash"

export default {
  name: "Answer",
  data() {
    return {
      topics: [],
      current: 0,
      type: 0,
      dialogTestVisible: false,
      num: 0
    }
  },
  created() {
    let type = this.$route.query.type || 0;
    this.type = parseInt(type)
    this.topics = this.getRandomArrayElements(lodash.cloneDeep(answers[this.type]), 10);
  },
  methods: {
    getRandomArrayElements(arr, count) {
      var shuffled = arr.slice(0),
          i = arr.length,
          min = i - count,
          temp, index;
      while (i-- > min) {
        index = Math.floor((i + 1) * Math.random());
        temp = shuffled[index];
        shuffled[index] = shuffled[i];
        shuffled[i] = temp;
      }
      return shuffled.slice(min);
    },
    changeRadio() {
      if (this.current < this.topics.length - 1) {
        this.current += 1;
      }
    },
    selectTopic(index) {
      this.current = index
    },
    moveTopic(e) {
      if (e === -1) {
        if (this.current === 0) {
          this.$message.error('已经是第一题啦～')
          return false
        } else {
          this.current -= 1;
        }
      }
      if (e === 1) {
        if (this.current < this.topics.length - 1) {
          this.current += 1;
        } else {
          this.$message.error('已经是最后一题啦～')
          return false
        }
      }
    },
    test() {
      let num = 0
      this.topics.forEach(item => {
        if (!item.userAnswer) {
          num++
        }
      })
      let title = '是否确认交卷？'
      if (num > 0) {
        title = '您还有' + num + '题未做完，是否确认交卷？'
      }
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }).then(() => {
        this.goScore()
      }).catch(() => {

      });
    },
    goScore() {
      let num = 0
      this.topics.forEach(item => {
        if (item.userAnswer && item.userAnswer === item.answer) {
          num += 10
        }
      })
      this.num = num
      this.dialogTestVisible = true
    },
    goIndex() {
      this.$router.push({
        name: 'Home'
      })
    },
    resetTopics() {
      this.current = 0
      this.topics = this.getRandomArrayElements(lodash.cloneDeep(answers[this.type]), 10)
      this.dialogTestVisible = false
    }
  }
}
</script>

<style scoped>
.bg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url("../assets/images/bg.jpeg");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-box {
  width: 70%;
  height: 50%;
}

.answer-item {
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: 100%;
}

.answer-left, .answer-right {
  background-color: rgba(255, 255, 255, .8);
  box-shadow: 0 0 10px 0 rgba(253, 245, 225, .3);
  border-radius: 8px;
  position: relative;
  height: 100%;
}

.answer-left {
  width: 75%;
}

.answer-right {
  width: 23%;
}

.answer-header {
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  border-bottom: 1px dashed #ebebeb;
}

.answer-type {
  background-color: #F56C6C;
  color: #FFFFFF;
  font-size: 12px;
  border-radius: 2px;
  width: 50px;
  height: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.answer-title {
  font-size: 16px;
  width: calc(100% - 55px);
}

.answer-right-header {
  display: flex;
  align-items: center;
  padding: 15px;
  border-bottom: 1px dashed #ebebeb;
}

.answer-right-image {
  width: 20px;
  height: 20px;
}

.answer-right-title {
  font-size: 16px;
  margin-left: 5px;
}

.answer-right-body {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.answer-card-item {
  border: 1px solid #ebebeb;
  width: 21%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  margin-bottom: 15px;
}

.answer-card-view {
  width: 21%;
  height: 0;
}

.answer-card-item:hover {
  background-color: rgba(245, 108, 108, .1);
  cursor: pointer;
}

.answer-card-item.current {
  background-color: #F56C6C;
  color: #FFFFFF;
  border-color: #F56C6C;;
}

.answer-options {
  padding: 15px;
}

.answer-radio {
  width: 100%;
  margin-bottom: 15px;
  white-space: normal;
}

.answer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  position: absolute;
  left: 0;
  bottom: 0;
  border-top: 1px solid #ebebeb;
}

.answer-bottom-item {
  width: 50%;
  border-right: 1px solid #ebebeb;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.answer-bottom-item:last-child {
  border-right: 0;
}

.answer-bottom-item:hover {
  background-color: rgba(245, 108, 108, .1);
  cursor: pointer;
}

.answer-test {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  padding: 15px;
}

.answer-test-save {
  width: 100%;
}

.test-success-image {
  width: 400px;
}

.test-box {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.test-num-box {
  width: 100%;
  text-align: center;
}

.test-num {
  font-size: 50px;
  color: #F56C6C;
  font-weight: bold;
}
</style>
